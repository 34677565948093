import React, {Component} from 'react';
import {DateTime} from "luxon";
import cx from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import './index.scss';

let itemWidth = 0
const invariantLanguage = 'en'

$(function () {
    const firstElement = $('.flat-date-picker-component .item-template').first()
    if (firstElement) itemWidth = firstElement.outerWidth(true)
});

class FlatDatePicker extends Component {
    constructor(props) {
        super(props)
        const options = JSON.parse(this.props.options)
        const {minDate, maxDate} = options
        const startDate = DateTime.fromISO(options.startDate)

        this.state = {
            startDate,
            minDate: minDate ? DateTime.fromISO(minDate) : null,
            maxDate: maxDate ? DateTime.fromISO(maxDate) : null,
            endDate: null,
            currentDate: startDate,
            options,
            items: [],
        }
    }

    componentDidMount() {
        const {startDate} = this.state
        this.calculateState(startDate)
    }

    calculateState = (startDate) => {
        const {renderMode} = this.state.options
        const containerWidth = $(this.timeline).innerWidth()
        let numItemsToRender = Math.floor(containerWidth / itemWidth)
        let lastMonthIndex = -1
        let date
        let firstDate = null
        let items = []

        for (let i = 0; i < numItemsToRender; ++i) {
            date = startDate
            let index = i

            switch (renderMode) {
                case 'Previous':
                    // Set a safe date margin minus one day
                    index += 1
                    date = date.minus({days: numItemsToRender - index})

                    if (!firstDate) {
                        firstDate = date
                    }
                    break
                case 'Next':
                    date = date.plus({days: i})
                    break
            }

            const monthIndex = date.month

            if (lastMonthIndex !== monthIndex) {
                --numItemsToRender
                lastMonthIndex = monthIndex
                items.push({type: 'month', value: date})
            }

            if (i < numItemsToRender) {
                items.push({type: 'day', value: date})
            }
        }

        this.setState({
            startDate,
            endDate: date,
            firstDate: firstDate,
            items,
        })
    }

    onDateClick = (event, date) => {
        const {onDateClick} = this.props
        event.preventDefault()

        if (onDateClick) onDateClick(date)
        this.setState({currentDate: date})
    }

    onPreviousClick = (event) => {
        event.preventDefault()
        this.stateGoBackInTime()
    }

    onLaterClick = (event) => {
        event.preventDefault()
        this.stateGoForwardInTime()
    }

    onResize = () => {
        const {startDate} = this.state
        this.calculateState(startDate)
    }

    stateGoBackInTime = () => {
        let {startDate, endDate, firstDate, options} = this.state
        const {renderMode} = options

        // Calc new start date
        let duration = null

        switch (renderMode) {
            case 'Previous':
                duration = endDate.diff(firstDate)
                this.calculateState(endDate.minus(duration))
                break
            case 'Next':
                duration = endDate.diff(startDate)
                this.calculateState(startDate.minus(duration))
                break
        }
    }

    stateGoForwardInTime = () => {
        let {startDate, endDate, firstDate, options} = this.state
        const {renderMode} = options

        // Calc new start date
        let duration = null

        switch (renderMode) {
            case 'Previous':
                duration = startDate.diff(firstDate)
                this.calculateState(startDate.plus(duration))
                break
            case 'Next':
                duration = endDate.diff(startDate)
                this.calculateState(startDate.plus(duration))
                break
        }
    }

    renderMonth = (date) => {
        const monthString = date.toFormat('MMM').replace('.', '')

        return (
            <div key={monthString} className="month">
                <i className="material-icons icon">today</i>
                <span>{monthString}</span>
            </div>
        )
    }

    renderDay = (date, today) => {
        const {currentDate, minDate, maxDate, options} = this.state
        const {renderMode} = options
        
        const isToday = date.hasSame(today, 'day')
        const isPast = renderMode === "Next"
            ? date.startOf('day') < today.startOf('day')
            : date.startOf('day') > today.startOf('day')
        const isActive = date.hasSame(currentDate, 'day')

        let isDisabled = false

        if (minDate && date.startOf('day') < minDate.startOf('day')) {
            isDisabled = true
        }

        if (maxDate && date.startOf('day') > maxDate.plus({days: 1}).startOf('day')) {
            isDisabled = true
        }

        const handler = !isDisabled ? (e) => this.onDateClick(e, date) : () => {
        }

        return (
            <div
                key={date.toFormat('yyyy-MM-dd')}
                onClick={handler}
                className={cx(
                    'day',
                    `day-${date.setLocale(invariantLanguage).toFormat('EEEE').toLowerCase()}`,
                    {
                        'day-past': isPast,
                        'day-today': isToday,
                        'active': isActive,
                        'disabled': isDisabled
                    })}>
                <span className="day-label">{date.toFormat('EEE').replace('.', '')}</span>
                <a className="value" href="#">{date.day}</a>
            </div>
        )
    }

    renderTimeline = () => {
        const {items} = this.state
        const today = DateTime.now()

        return items.map(item => {
            switch (item.type) {
                case 'month':
                    return this.renderMonth(item.value)
                case 'day':
                    return this.renderDay(item.value, today)
            }
        });
    }

    render() {
        const {id} = this.props
        const {startDate} = this.state

        return (
            <React.Fragment>
                <div className="date-info d-none d-md-flex">
                    {startDate.toFormat('MMMM yyyy')}
                </div>
                <div className="h-bar">
                    <a className="goto-past-button" onClick={this.onPreviousClick}>
                        <i className="material-icons icon">chevron_left</i>
                    </a>
                    <div ref={(element) => {
                        this.timeline = element
                    }} className="timeline">
                        {this.renderTimeline()}
                    </div>
                    <a className="goto-future-button" onClick={this.onLaterClick}>
                        <i className="material-icons icon">chevron_right</i>
                    </a>
                </div>
                <ReactResizeDetector handleWidth onResize={this.onResize}/>
            </React.Fragment>
        );
    }
}

export default FlatDatePicker