import '../../../../Assets/app.base';
import React from 'react';
import renderComponent from '../../../../Assets/scripts/composition/renderComponent';

import './Index.scss';

// Components
import '../../../../Components/Navbar';
import FilterBarComponent from '../../../../Components/FilterBar';
import FlatDatePicker from '../../../../Components/FlatDatePicker';
import '../../../../Components/InfiniteScrollView';
import '../../../../Components/NoResultsWarning';
import initEventListComponent from '../../../../Components/EventList';

function getEventsForDate(date) {
    const dateFormatted = date.toFormat('dd-MM-yy')
    const url = window.eventCategoryControllerInit.EventCategoryBaseUrl.replace('01-01-01', dateFormatted)
    window.location.replace(url)
}

// This will run when DOM is loaded
function init() {
    const infiniteScrollComponent = $('#event-scroll-container')
    infiniteScrollComponent.on('onContentLoad', function (event, element) {
        initEventListComponent(element)
    })

    renderComponent(FlatDatePicker, document.getElementById('date-picker'), { onDateClick: getEventsForDate })
    const filterBar = new FilterBarComponent()

    $('#filter-btn').click(() => {
        filterBar.toggle()
    })
}

$(function () {
    init()
})
